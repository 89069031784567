
import CommonMixin from '@/mixins/CommonMixin';
import ToolsMixin from '@/mixins/ToolsMixin';
import PaginationMixin from '@/mixins/PaginationMixin';
import { createColumn } from '@/util/table';
import { SalesTemplateMap } from '@/models/SalesTemplateMap';
import { downloadAsBlob, getMimeFromFilename } from '@/util/file';

import { handleErrorUI } from '@/util/error';
import { mapState } from 'vuex';
import { checkPermission } from '@/api/utils';
import { kpiListExtensionTemplate } from '@/util/mockData/kpiMapping';
export default {
  mixins: [CommonMixin, ToolsMixin, PaginationMixin],
  data() {
    return {
      data: [],
      showDialog: false,
      loading: true,
      selectedKPI: 'sales',
      selectedTemplate: null,
      selectedTemplateName: '',
      options: [],
      deleteConfirmationShown: false,
      columns: [
        createColumn('mappingName', 'Template Name'),
        createColumn('fileName', 'Uploaded File'),
        createColumn('action', 'Action', { sortable: false })
      ]
    };
  },
  computed: {
    importMappingDialogKey() {
      const prefix = 'import-mapping-dialog';
      if (this.selectedTemplate == null) return prefix;
      return `${prefix}-${this.selectedTemplate.id}`;
    },
    optionsKpi() {
      return this.kpiListOrder.map(kpi => ({
        label: this.$t(`KPI.${kpi}`),
        value: kpi
      }));
    }
  },
  watch: {
    selectedKPI(val) {
      this.fetchTemplates();
    }
  },
  async mounted() {
    await this.fetchTemplates();
  },
  created() {
    this.options = [...kpiListExtensionTemplate];
  },
  methods: {
    viewAllowed(name) {
      return checkPermission(name);
    },
    addNewTemplate() {
      this.selectedTemplate = null;
      this.showDialog = true;
    },
    async onNewSalesTemplateSaved() {
      this.selectedTemplate = null;
      await this.fetchTemplates();
    },
    editMappingTemplate(map: SalesTemplateMap) {
      this.selectedTemplate = map;
      this.showDialog = true;
    },
    deleteTemplate(map: SalesTemplateMap) {
      this.selectedTemplate = map;
      this.selectedTemplateName = map.mappingName;
      this.deleteConfirmationShown = true;
    },
    async onTemplateDelete() {
      await this.$repo.extension.deleteSalesTemplateMapping(
        this.selectedTemplate.id,
        this.selectedKPI
      );
      await this.fetchTemplates();
    },
    async fetchTemplates() {
      this.loading = true;
      try {
        const {
          data,
          pagination
        } = await this.$repo.extension.getSalesTemplates(
          this.selectedKPI,
          this.pagination
        );
        this.data = data;
        this.updatePagination(pagination);
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      }
    },
    async paginationChangeHandler() {
      await this.fetchTemplates();
    },
    async downloadTemplate(map: SalesTemplateMap) {
      const data = await this.$repo.extension.downloadSalesMappingTemplate(
        map,
        this.selectedKPI
      );
      downloadAsBlob(data, getMimeFromFilename(map.fileName), map.fileName);
    }
  }
};
